import { Fragment, useEffect, useState } from 'react'
import { ethers, BigNumber } from 'ethers'
import LPXMas from './MignonWorldsGenesis.json'

import { lazy, Suspense } from 'react';

import ReactImageFallback from "react-image-fallback";

import Tilty from 'react-tilty';

import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'

import "./tiltystyles.css";

import Logo from './logo.png'
import Nyc from './hidden.PNG'

const LPXMasNFTAddress = "0x551056ee6823b97220845981cf4f8469A521115F";

const AlchemyURL = "https://eth-mainnet.g.alchemy.com/v2/brfkLhmIDJAKoXWpoBud2ShlSpXafg8f";

const navigation = [
  { name: 'Twitter', href: 'https://twitter.com/mignonverse' },
  { name: 'Discord', href: 'https://discord.gg/x7AQ2VZ9yY' },
  { name: 'Opensea', href: 'https://opensea.io/collection/mignon-worlds-genesis' },
  { name: 'LooksRare', href: 'https://looksrare.org/collections/0x551056ee6823b97220845981cf4f8469A521115F' },
  { name: 'Verified Smart Contract', href: 'https://etherscan.io/address/0x551056ee6823b97220845981cf4f8469A521115F#code' },
]

export default function Mint() {

  const getInitialState = () => {
    const numberOfMints = "1";
    return numberOfMints;
  };

  const [numberOfMints, setNumberOfMints] = useState(getInitialState);

  const handleChange = (e) => {
    console.log(e.target.value);
    setNumberOfMints(e.target.value);
  };


    const [accounts, setAccounts] = useState([]);
    const [mintMessage, setMintMessage] = useState("");
    const [pendingMint, setPendingMint] = useState(false);
    const [pendingDreamMint, setPendingDreamMint] = useState(false);
    const [pendingAllowListMint, setPendingAllowListMint] = useState(false);
    const [pendingPublicMint, setPendingPublicMint] = useState(false);


    const [connectButtonStr, setConnectButtonStr] =  useState('Connect');
    const [connectedAccountStr, setConnectedAccountStr] = useState('');
    const [isCorrectWalletNetwork, setIsCorrectWalletNetwork] = useState(false);

    // const [currentDayOfChristmas, setCurrentDayOfChristmas] = useState(0);
    const [isVisionnaire, setIsVisionnaire] = useState(false);
    const [isAlittleDreamHolder, setIsAlittleDreamHolder] = useState(false);
    const [isAllowListed, setIsAllowListed] = useState(false);
    const [isPublicMintStarted, setIsPublicMintStarted] = useState(true);

    const isConnected = Boolean(accounts[0]);

    async function connectAccount(){
        if (window.ethereum) {
            const accountsz = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            setAccounts(accountsz);

            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send("eth_requestAccounts", []);
    
          
            const accounts = await provider.listAccounts();
    
            const currentAccountAddress = accounts[0];
      
            const network = await provider.getNetwork();
            const networkName = network.name;
      
            const balanceBigNumber = await provider.getBalance(currentAccountAddress);
      
            const balance = ethers.utils.formatEther(balanceBigNumber.toString());
      
            setConnectButtonStr(parseFloat(balance).toFixed(2).toString() + " Ξ");
            setConnectedAccountStr((currentAccountAddress).substring(0, 6) + '...' + (currentAccountAddress).slice(-4));
            setAccounts(currentAccountAddress);
            
            if(network.name === 'homestead'){ //homestead
                setIsCorrectWalletNetwork(true);
                setMintMessage("");
            } else {
              setMintMessage("Please change to Ethereum Main network!");  
              //setMintMessage("Please change to Goerli network!");
            }
            



        }
    }

    useEffect(() => {
      
        if(window.ethereum) {
          window.ethereum.on('chainChanged', () => {
            window.location.reload();
          })
          window.ethereum.on('accountsChanged', () => {
            window.location.reload();
          })
        }
      });

      async function getContactStatus(){
        const currentAccountAddress = accounts[0];
        console.log("currentAccountAddress", currentAccountAddress);
        const providery = new ethers.providers.JsonRpcProvider(AlchemyURL);
        const contracty = new ethers.Contract(
          LPXMasNFTAddress,
          LPXMas.abi,
          providery
        );
    
        if(!currentAccountAddress) return;
        const isInVisionnaire = await contracty.visionnaireMints(currentAccountAddress);
        console.log("isInVisionnaire", isInVisionnaire.toNumber());
        setIsVisionnaire(isInVisionnaire.toNumber());

        const isInAlittleDream = await contracty.aLittleDreamAllowList(currentAccountAddress);
        console.log("isInAlittleDream", isInAlittleDream.toNumber());
        setIsAlittleDreamHolder(isInAlittleDream.toNumber());

        const isAllowListed = await contracty.allowListMints(currentAccountAddress);
        console.log("isAllowListed", isAllowListed.toNumber());
        setIsAllowListed(isAllowListed.toNumber());

        const isPublicMintStarted = await contracty.isPublicMintEnabled();
        console.log("isPublicMintStarted", isPublicMintStarted);
        setIsPublicMintStarted(isPublicMintStarted);

      }

    useEffect(() => {
      getContactStatus();
    }, [isConnected])


    useEffect(() => {

        const checkconnectedWallet = async () => {
          
          if(window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
    
            const accountsConnected = await provider.listAccounts();

            if(accountsConnected.length) {
                connectAccount();
            } else {
              
              setConnectButtonStr("Connect")
            }
      
          } else {
            setConnectButtonStr("Please install MetaMask");
          }
        }
        checkconnectedWallet();
      }, []);


    async function handleMint() {
        if (window.ethereum) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(
                LPXMasNFTAddress,
                LPXMas.abi,
                signer
            );
            try {
                const response = await contract.claimForVisionnaires();
                setMintMessage("Minting started!")
                setPendingMint(true);

                const txn_test = await provider.waitForTransaction(response.hash.toString());
            if (txn_test) {
              setMintMessage("Tx: ", txn_test.transactionHash.toString());
                if (txn_test.blockNumber) {
                  setMintMessage("Mint Successful! Thank you!");
                  setPendingMint(false);
                }
            }
                //setMintMessage(response.message.toString());
            } catch (err) {
                var errMessage = err.message;
                var errMessageCleanup = errMessage.replace("execution reverted: ","");
                setMintMessage(errMessageCleanup);
            }
        }
    }

    async function handleDreamMint() {
      if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(
              LPXMasNFTAddress,
              LPXMas.abi,
              signer
          );
          try {
              const response = await contract.claimForALittleDreamers();
              setMintMessage("Minting started!")
              setPendingDreamMint(true);

              const txn_test = await provider.waitForTransaction(response.hash.toString());
          if (txn_test) {
            setMintMessage("Tx: ", txn_test.transactionHash.toString());
              if (txn_test.blockNumber) {
                setMintMessage("Mint Successful! Thank you!");
                setPendingDreamMint(false);
              }
          }
              //setMintMessage(response.message.toString());
          } catch (err) {
              var errMessage = err.message;
              var errMessageCleanup = errMessage.replace("execution reverted: ","");
              setMintMessage(errMessageCleanup);
          }
      }
  }

  const mintCost = 0.09;
  const maxAllowListMintAmount = 1;
  
  async function handleAllowListMint() {
    if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
            LPXMasNFTAddress,
            LPXMas.abi,
            signer
        );
        try {
            const response = await contract.mintAllowList({
                            value: ethers.utils.parseEther((mintCost * maxAllowListMintAmount).toString()),
                        });
            setMintMessage("Minting started!")
            setPendingAllowListMint(true);

            const txn_test = await provider.waitForTransaction(response.hash.toString());
        if (txn_test) {
          setMintMessage("Tx: ", txn_test.transactionHash.toString());
            if (txn_test.blockNumber) {
              setMintMessage("Mint Successful! Thank you!");
              setPendingAllowListMint(false);
            }
        }
            //setMintMessage(response.message.toString());
        } catch (err) {
            var errMessage = err.message;
            var errMessageCleanup = errMessage.replace("execution reverted: ","");
            setMintMessage(errMessageCleanup);
        }
    }
}   

const publicMintCost = 0.19;

async function handlePublicMint() {
  if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
          LPXMasNFTAddress,
          LPXMas.abi,
          signer
      );
      try {
          const response = await contract.mint(BigNumber.from(numberOfMints), {
            value: ethers.utils.parseEther((publicMintCost * numberOfMints).toString()),
        });
          setMintMessage("Minting started!")
          setPendingPublicMint(true);

          const txn_test = await provider.waitForTransaction(response.hash.toString());
      if (txn_test) {
        setMintMessage("Tx: ", txn_test.transactionHash.toString());
          if (txn_test.blockNumber) {
            setMintMessage("Mint Successful! Thank you!");
            setPendingPublicMint(false);
          }
      }
          //setMintMessage(response.message.toString());
      } catch (err) {
          var errMessage = err.message;
          var errMessageCleanup = errMessage.replace("execution reverted: ","");
          setMintMessage(errMessageCleanup);
      }
  }
}  

    

    return (
    <div className="relative overflow-hidden">
      <Popover as="header" className="relative">
        <div className=" pt-6">
          <nav
            className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
            aria-label="Global"
          >
            <div className="flex items-center flex-1">
              <div className="flex items-center justify-between w-full md:w-auto">
                <a href="#">
                  <span className="sr-only">12 Days</span>
                  <img
                    className="h-20 w-auto sm:h-20"
                    src={Logo}
                    alt=""
                  />
                </a>
                <div className="-mr-2 flex items-center md:hidden">
                  <Popover.Button className=" rounded-md p-2 inline-flex items-center justify-center text-pink-700 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="hidden space-x-8 md:flex md:ml-10">
                {navigation.map((item) => (
                  <a key={item.name} href={item.href} target="_blank" className="text-base font-medium text-white hover:text-pink-700">
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
            <div className="hidden md:flex md:items-center md:space-x-6">
              <button className="text-base font-medium text-white hover:text-pink-700">
                {connectedAccountStr}
              </button>

              {isConnected && isCorrectWalletNetwork ? (
                <p>{connectButtonStr}</p>
              ):(
              <button

                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-pink-700"
                onClick={connectAccount}
              >
                {connectButtonStr}
              </button>
              )}
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel focus className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden">
            <div className=" rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-80 border border-gray-100
">
              <div className="px-5 pt-4 flex items-center justify-between">
                <div>
                  <img
                    className="h-20 w-auto"
                    src={Logo}
                    alt="xmas"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-pink-700 rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-600">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="pt-5 pb-6">
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      target="_blank"
                      className="block px-3 py-2 rounded-md text-base font-medium text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="mt-6 px-5">
                {isConnected && isCorrectWalletNetwork ? (
                <p className="block text-center w-full py-3 px-4 rounded-md shadow bg-pink-600 text-white font-medium hover:bg-pink-700">{connectButtonStr}</p>
              ):(
                  <button
                    
                    className="block text-center w-full py-3 px-4 rounded-md shadow bg-pink-600 text-white font-medium hover:bg-pink-700"
                    onClick={connectAccount}
                  >
                    {connectButtonStr}
                  </button>
                  )}
                </div>
                <div className="mt-6 px-5">
                  <p className="text-center text-base font-medium text-gray-500">
                    
                    <button className="text-gray-900 hover:underline">
                        {connectedAccountStr}
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

      <main>
        <div className="pt-10  sm:pt-16 lg:pt-8 lg:pb-14 lg:overflow-hidden">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                <div className="lg:py-24">
                  <a
                    
                    className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                  >
                    <span className="px-3 py-0.5 text-white text-xs leading-5 uppercase tracking-wide bg-pink-700 rounded-full">
                      Mignon Worlds Genesis
                    </span>
                    <span className="ml-4 text-sm">By Mignon Inc</span>
                    <ChevronRightIcon className="ml-2 w-5 h-5 text-gray-500" aria-hidden="true" />
                  </a>
                  <h1 className="mt-4 text-4xl tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                    <span className="block">Mignon Worlds</span>
                    <span className="block text-black">Buy/claim your ticket</span>
                  </h1>
                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  gm, Pioneers 🧑‍🚀
                  </p>
                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  If you here, you’re still early… but actually, you’re just on time… On time for a unique Journey and we are about to take-off and the seats are limited.
                  </p>
                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  “Ground Control the The Mignonverse Team - Are the 17 Rockets ready to blast-off?” 🚀
                  </p>                                    
                  <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Lapin Mignon & Tagachi ❤️
                  </p>

                  <div className="mt-10 sm:mt-12">
                    
                      
                        {isConnected && isCorrectWalletNetwork && isVisionnaire ? (
                          <div className="sm:flex">
                          <div className="mt-3 sm:mt-0">
                          <button
                              type="submit"
                              className={`block w-full py-3 px-4 rounded-md shadow ${!pendingMint ? 'bg-pink-700' : 'bg-gray-700' } text-white font-medium hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 focus:ring-offset-gray-900`}
                              onClick={handleMint}
                              disabled={pendingMint}
                            >
                              Visionnaires Claim 
                            </button>
                          </div>
                          </div>
                        ) : null}
                        
                        {isConnected && isCorrectWalletNetwork && isAlittleDreamHolder ? (
                          <div className="sm:flex">
                          <div className="mt-3 sm:mt-0">
                          <button
                              type="submit"
                              className={`block w-full py-3 px-4 rounded-md shadow ${!pendingDreamMint ? 'bg-pink-700' : 'bg-gray-700' } text-white font-medium hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 focus:ring-offset-gray-900`}
                              onClick={handleDreamMint}
                              disabled={pendingDreamMint}
                            >
                              A Little Dreamer Claim 
                            </button>
                          </div>
                          </div>
                        ) : null}

                        {isConnected && isCorrectWalletNetwork && isAllowListed ? (
                          <div className="sm:flex">
                          <div className="mt-3 sm:mt-0">
                          <button
                              type="submit"
                              className={`block w-full py-3 px-4 rounded-md shadow ${!pendingAllowListMint ? 'bg-pink-700' : 'bg-gray-700' } text-white font-medium hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 focus:ring-offset-gray-900`}
                              onClick={handleAllowListMint}
                              disabled={pendingAllowListMint}
                            >
                              Allow List Mint @ 0.09 ETH 
                            </button>
                          </div>
                          </div>
                        ) : null}

                        {isConnected && isCorrectWalletNetwork && isPublicMintStarted && (
                          <div className="sm:flex">
                          <div className="mt-3 sm:mt-0">
                          <label htmlFor="numberOfMints" className="block text-md font-medium text-gray-700">
        Public Mint
      </label>
      <select
        id="numberOfMints"
        name="numberOfMints"
        className="mr-2 w-20 rounded-md py-3 px-4 border-gray-300 text-lg focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
        defaultValue="1"
        value={numberOfMints} 
        onChange={handleChange}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
        <option value="5">5</option>
        <option value="6">6</option>
        <option value="7">7</option>
        <option value="8">8</option>
                         

      </select>
                          <button
                              type="submit"
                              className={` w-90 py-3 px-4 rounded-md shadow ${!pendingPublicMint ? 'bg-pink-700' : 'bg-gray-700' } text-white font-medium hover:bg-pink-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-300 focus:ring-offset-gray-900`}
                              onClick={handlePublicMint}
                              disabled={pendingPublicMint}
                            >
                              Public Mint @ 0.19 ETH 
                            </button>
                          </div>
                          </div>
                        )}                        
                        

                      
                      <p className="mt-3 text-sm text-pink-700 sm:mt-4">
                        {mintMessage} 
                        {/* <a href="#" className="font-medium text-white">
                          terms of service
                        </a> */}
                      </p>

                  </div>
                </div>
              </div>
              <div className="mt-12 -mb-16 sm:-mb-48 lg:py-24 lg:m-0 lg:relative ">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 ">
                  {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}


                  <Tilty className="tilty " options={{ max: 25, scale: 1.05, speed: 400, glare: true, "max-glare": 0.5, "reset" :true }}>
                  <ReactImageFallback
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none "
                    src={Nyc}
                    fallbackImage={Nyc}
                    initialImage={Nyc}
                    alt="Mignon Worlds Genesis"
                  />
                  </Tilty>
                  {/* <img
                    className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={process.env.PUBLIC_URL + '/daypics/' + currentDayOfChristmas + '.GIF'}
                    alt=""
                  /> */}
                  {/* <Tilty className="tilty w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none" glare scale={1.05} maxGlare={0.5}>
        
      </Tilty> */}
                  
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 pt-8">
            <p className="text-base text-gray-700 xl:text-center">&copy; 2023 Mignon Inc. All rights reserved.</p>
            <p className="text-base text-gray-700 xl:text-center">Art by Lapin Mignon. Code by Tagachi.</p>
          </div>
        </div>

        {/* More main page content here... */}
      </main>
    </div>
  )
}
