import './App.css';
import Mint from './Mint';

function App() {
  return (
    <div className="App bg-slate-400">
      <Mint/>
    </div>
  );
}

export default App;
